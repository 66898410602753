<template>
  <a-tabs class="review-details-tab" :animated="false" v-model:activeKey="activeTab" @tabClick="tabClick" type="editable-card" hide-add>
    <a-tab-pane class="review-details-tab-pane" v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">
      <div v-if="pane.key === 'review'" :class="{ 'text-center': loading }">
        <a-spin v-if="loading" class="p-5" />
        <div v-else>
          <div v-if="review">
            <div class="row mx-2">
              <div class="col-md-6 px-0">
                <a-list>
                  <a-list-item>
                    <a-list-item-meta :description="review.store.name">
                      <template #title>
                        <div>Заведение</div>
                      </template>
                      <template #avatar>
                        <img :src="review.store.logo_url" class="store-logo" />
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
              <div class="col-md-6">
                <a-list>
                  <a-list-item>
                    <a-list-item-meta :description="review.employee.name">
                      <template #title>
                        <div>Сотрудник</div>
                      </template>
                      <template #avatar>
                        <a-avatar class="cover" :src="review.employee.photo_url" />
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
            </div>
            <hr>
            <a-comment class="review-details-comment">
              <template #actions>
                <span class="comment-like" key="comment-basic-like">
                  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.8333 0.166626H2.16665C1.2879 0.166626 0.59123 0.871209 0.59123 1.74996L0.583313 11.25C0.583313 12.1287 1.2879 12.8333 2.16665 12.8333H14.8333C15.7121 12.8333 16.4166 12.1287 16.4166 11.25V1.74996C16.4166 0.871209 15.7121 0.166626 14.8333 0.166626ZM14.8333 11.25H2.16665V6.49996H14.8333V11.25ZM14.8333 3.33329H2.16665V1.74996H14.8333V3.33329Z" fill="#616161"/>
                  </svg>
                  <span v-if="review.tips">
                    <span v-if="review?.payment?.commission">{{ formattedBalance(review.tips - (review.payment.commission / 100)) }}</span>
                    <span v-else>{{ formattedBalance(review.tips) }}</span>
                  </span>
                  <span v-else>Без чаевых</span>
                </span>
                <a
                  v-if="review.user?.phone"
                  :href="'tel:' + review.user?.phone"
                  :class="{
                    'text-muted disabled': !review.user?.phone,
                    'text-success': review.user?.phone
                  }"
                  class="comment-dislike phone-link"
                  key="comment-basic-dislike"
                >
                  <span>{{ formattedPhone(review.user.phone) }}</span>
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.24083 6.54208C4.38083 8.7825 6.2175 10.6112 8.45792 11.7592L10.1996 10.0175C10.4133 9.80375 10.73 9.7325 11.0071 9.8275C11.8938 10.1204 12.8517 10.2788 13.8333 10.2788C14.2688 10.2788 14.625 10.635 14.625 11.0704V13.8333C14.625 14.2688 14.2688 14.625 13.8333 14.625C6.39958 14.625 0.375 8.60042 0.375 1.16667C0.375 0.73125 0.73125 0.375 1.16667 0.375H3.9375C4.37292 0.375 4.72917 0.73125 4.72917 1.16667C4.72917 2.15625 4.8875 3.10625 5.18042 3.99292C5.2675 4.27 5.20417 4.57875 4.9825 4.80042L3.24083 6.54208Z" fill="#616161"/>
                  </svg>
                </a>
<!--                <a v-else href="#" @click.prevent="" class="comment-dislike phone-link text-muted disabled">-->
<!--                  <span>Позвонить</span>-->
<!--                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M3.24083 6.54208C4.38083 8.7825 6.2175 10.6112 8.45792 11.7592L10.1996 10.0175C10.4133 9.80375 10.73 9.7325 11.0071 9.8275C11.8938 10.1204 12.8517 10.2788 13.8333 10.2788C14.2688 10.2788 14.625 10.635 14.625 11.0704V13.8333C14.625 14.2688 14.2688 14.625 13.8333 14.625C6.39958 14.625 0.375 8.60042 0.375 1.16667C0.375 0.73125 0.73125 0.375 1.16667 0.375H3.9375C4.37292 0.375 4.72917 0.73125 4.72917 1.16667C4.72917 2.15625 4.8875 3.10625 5.18042 3.99292C5.2675 4.27 5.20417 4.57875 4.9825 4.80042L3.24083 6.54208Z" fill="#616161"/>-->
<!--                  </svg>-->
<!--                </a>-->
              </template>
              <template #author><a class="review-details-username">{{ formattedUsername(review.user?.name) }}</a></template>
              <template #content>
                <p>{{ review.comment }}</p>
              </template>
              <template #datetime>
                <span v-for="i in review.rating" :key="i">
                  <StarTwoTone :style="{ fontSize: '18px' }" :two-tone-color="'#00b200'" />
                </span>
                {{ formattedDate(review.added_on) }}
              </template>
            </a-comment>
            <a-comment class="review-details-comment" v-if="review.gif || review.image || review.audio || review['stickersArray']">
              <template #actions>
                <ul v-if="review['stickersArray']" class="stickers-list">
                  <li v-for="sticker in review['stickersArray']" :key="sticker.id">
                    <img height="35" :src="sticker.icon" alt="">
                    <span>{{ sticker.text }}</span>
                  </li>
                </ul>
                <img v-if="review.gif" :src="review.gif" height="100" alt="" />
                <img v-if="review.image" :src="review.image" height="100" alt="" />
                <audio v-if="review.audio" :src="review.audio" controls />
              </template>
            </a-comment>
            <div class="comments" :class="{ loading: commentsLoading }" v-if="comments.length">
              <hr>
              <a-spin v-if="commentsLoading" />
              <h6 class="text-secondary">Комментарии</h6>
              <a-comment v-for="item in comments" :key="item.id">
                <template #actions v-if="item['can_edit'] && !item.show_form">
                  <a-button @click="item.show_form = !item.show_form" type="link" shape="round" size="small" class="p-0">
                    <template #icon>
                      <EditOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Изменить</span>
                    </template>
                  </a-button>
                  <a-button @click="updateComment(item, false, 'delete')" type="link" shape="round" size="small" danger>
                    <template #icon>
                      <DeleteOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Удалить</span>
                    </template>
                  </a-button>
                </template>
                <template #author>
                  <span class="text-black-50" v-if="review.log['payment_canceled_on']">Отмена платежа: </span>
                  <a>{{ item.author?.username }}</a>
                </template>
                <template #avatar>
                  <a-avatar shape="square" :size="43" style="background-color: #e4e9f0">
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                </template>
                <template #content>
                  <p v-if="!item.show_form">{{ item.comment }}</p>
                  <div v-else>
                    <textarea class="ant-input mt-2 mb-3" name="comment" :id="item.id" rows="2" v-model="item.comment"></textarea>
                    <a-button type="link" size="small" @click="updateComment(item, false, 'update')">
                      <SaveOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Сохранить</span>
                    </a-button>
                    <a-button type="link" size="small" danger @click="item.show_form = false">
                      <DeleteOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Отменить</span>
                    </a-button>
                  </div>
                </template>
                <template #datetime>
                  <a-tooltip :title="item['added_on']">
                    <span class="cursor-pointer">{{ formattedDate(item['added_on']) }}</span>
                  </a-tooltip>
                </template>
              </a-comment>
            </div>
            <div class="solutions" :class="{ loading: solutionsLoading }" v-if="solutions.length">
              <hr>
              <h6 class="text-secondary">Решения</h6>
              <a-spin v-if="solutionsLoading" />
              <a-comment v-for="item in solutions" :key="item.id">
                <template #actions v-if="item['can_edit'] && !item.show_form">
                  <a-button @click="item.show_form = !item.show_form" type="link" shape="round" size="small" class="p-0">
                    <template #icon>
                      <EditOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Изменить</span>
                    </template>
                  </a-button>
                  <a-button @click="updateComment(item, true, 'delete')" type="link" shape="round" size="small" danger>
                    <template #icon>
                      <DeleteOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Удалить</span>
                    </template>
                  </a-button>
                </template>
                <template #author><a>{{ item.author?.username }}</a></template>
                <template #avatar>
                  <a-avatar shape="square" :size="43" style="background-color: #e4e9f0">
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                </template>
                <template #content>
                  <p v-if="!item.show_form">{{ item.comment }}</p>
                  <div v-else>
                    <textarea class="ant-input mt-2 mb-3" name="comment" :id="item.id" rows="2" v-model="item.comment"></textarea>
                    <a-button type="link" size="small" @click="updateComment(item, true, 'update')">
                      <SaveOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Сохранить</span>
                    </a-button>
                    <a-button type="link" size="small" danger @click="item.show_form = false">
                      <DeleteOutlined :style="{ fontSize: '13px' }" />
                      <span :style="{ fontSize: '13px' }">Отменить</span>
                    </a-button>
                  </div>
                </template>
                <template #datetime>
                  <a-tooltip :title="item['added_on']">
                    <span class="cursor-pointer">{{ formattedDate(item['added_on']) }}</span>
                  </a-tooltip>
                </template>
              </a-comment>
            </div>
            <div class="row">
              <div class="col-6">
                <a-comment class="no-avatar">
                  <template #content>
                    <label class="">Комментарий</label>
                    <a-form-item>
                      <a-textarea v-model:value="comment" :rows="4" />
                      <a-button
                        :loading="commentButtonLoading"
                        @click="sendComment(false)"
                        type="default"
                        class="mt-3"
                      >Отправить</a-button>
                    </a-form-item>
                  </template>
                </a-comment>
              </div>
              <div class="col-6">
                <a-comment class="no-avatar">
                  <template #content>
                    <label class="">Решение</label>
                    <a-form-item>
                      <a-textarea v-model:value="solution" :rows="4" />
                      <a-button
                        :loading="solutionButtonLoading"
                        @click="sendComment(true)"
                        type="default"
                        class="mt-3"
                      >Отправить</a-button>
                    </a-form-item>
                  </template>
                </a-comment>
              </div>
            </div>
          </div>
          <hr>
          <div v-if="review && review.status === 'closed'">
            <a-button
              @click="updateStatus('progress')"
              class="ml-2 float-left"
              key="submit"
              type="default"
              :loading="loading"
            >Вернуть в обработку</a-button>
          </div>
          <div v-else>
            <a-button
              :disabled="!(review && (review.status === 'new' || review.status === null))"
              @click="updateStatus('progress')"
              class="mr-2 float-left"
              key="submit"
              type="default"
              :loading="loading"
            >Взять в работу</a-button>
            <a-button
              :disabled="!(review && review.status === 'progress')"
              @click="updateStatus('completed')"
              key="submit"
              class="float-left"
              type="default"
              :loading="loading"
            >Завершить</a-button>
          </div>
          <a-button
            @click="close"
            danger
            class="float-right"
            >Закрыть</a-button>
        </div>
      </div>
      <div v-if="pane.key === 'cheque'" class="grid">
        <a-card :title="null" class="order-details-card">
          <div class="clearfix">
            <div
              class="order-number float-left"
            >Счет № {{ orderDetails.number ? orderDetails.number : orderDetails.Number }}</div>
            <div class="float-right">
              <a-avatar class="contain cheque-store-logo" :src="review.store.logo_url"></a-avatar>
            </div>
          </div>
          <a-list
            class="order-details-list"
            v-if="orderDetails.items || orderDetails.Items" item-layout="horizontal"
            :data-source="orderDetails.items ? orderDetails.items : orderDetails.Items">
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta>
                  <template #description>
                    <div class="product-quantity">
                      {{ item.count }} x {{ formattedBalance(item.price, true) }}
                    </div>
                  </template>
                  <template #title>
                    <div class="clearfix">
                      <div class="float-left product-name">{{ item.name }}</div>
                      <div class="float-right product-price">{{ formattedBalance(item.price * item.count, true) }}</div>
                    </div>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
            <a-list-item class="order-details-total">
              <a-list-item-meta>
                <template #description>
                  <!--
                  <div class="clearfix">
                    <div class="float-left">Счет:</div>
                    <div class="float-right">{{ formattedBalance(orderDetails.orderSum) }}</div>
                  </div>
                  <div class="clearfix">
                    <div class="float-left">Доставка:</div>
                    <div class="float-right">{{ formattedBalance(orderDetails.orderSum) }}</div>
                  </div>
                  -->
                </template>
                <template #title>
                  <div class="clearfix">
                    <div class="float-left">Итого:</div>
                    <div class="float-right">{{
                        orderDetails.orderSum
                          ? formattedBalance(orderDetails.orderSum, true)
                          : formattedBalance(orderDetails.OrderSum, true)
                      }}</div>
                  </div>
                </template>
              </a-list-item-meta>
            </a-list-item>

            <a-list-item class="order-details-address" v-if="orderDetails.address || orderDetails.Address">
              <a-list-item-meta>
                <template #description>
                  <div id="map"></div>
                  {{ orderDetails.address ? orderDetails.address : orderDetails.Address }}
                </template>
                <template #title>
                  <div class="address-title">Адрес доставки</div>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </a-card>
      </div>
      <div v-if="pane.key === 'reviews'">
        <div v-for="pastReview in pastReviews" :key="pastReview.id">
          <div class="row mx-2">
            <div class="col-md-8 past-review-bordered px-0">
              <a-list class="past-reviews-list">
                <a-list-item v-if="pastReview.employee">
                  <a-list-item-meta>
                    <template #description>
                    <span class="past-review-rating">
                      <span v-for="i in parseInt(pastReview.rating)" :key="i">
                        <StarTwoTone :style="{ fontSize: '18px' }" :two-tone-color="'#00b200'" />
                      </span>
                    </span>
                      <span class="past-review-added-on">{{ formattedDate(pastReview.added_on, true) }}</span>
                    </template>
                    <template #title>
                      <div class="past-review-employee-name">{{ pastReview.employee.name }}</div>
                    </template>
                    <template #avatar>
                      <a-avatar class="cover" :src="pastReview.employee.photo_url" />
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
            <div class="col-md-4">
              <a-list class="past-reviews-list">
                <a-list-item v-if="pastReview.store">
                  <a-list-item-meta>
                    <template #title>
                      <div class="past-review-store-title">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.8 0.899941L0.8 5.39994C0.3 5.77994 0 6.36994 0 6.99994V15.9999C0 17.0999 0.9 17.9999 2 17.9999H14C15.1 17.9999 16 17.0999 16 15.9999V6.99994C16 6.36994 15.7 5.77994 15.2 5.39994L9.2 0.899941C8.49 0.369941 7.51 0.369941 6.8 0.899941ZM8.5 9.49994C8.5 10.3299 7.83 10.9999 7 10.9999V14.4999C7 14.7799 6.78 14.9999 6.5 14.9999C6.22 14.9999 6 14.7799 6 14.4999V10.9999C5.17 10.9999 4.5 10.3299 4.5 9.49994V6.99994C4.5 6.71994 4.72 6.49994 5 6.49994C5.28 6.49994 5.5 6.71994 5.5 6.99994V9.49994H6V6.99994C6 6.71994 6.22 6.49994 6.5 6.49994C6.78 6.49994 7 6.71994 7 6.99994V9.49994H7.5V6.99994C7.5 6.71994 7.72 6.49994 8 6.49994C8.28 6.49994 8.5 6.71994 8.5 6.99994V9.49994ZM10.5 14.9999C10.22 14.9999 10 14.7799 10 14.4999V11.4999H9.5C9.22 11.4999 9 11.2799 9 10.9999V8.49994C9 7.61994 9.57 6.86994 10.36 6.60994C10.67 6.49994 11 6.74994 11 7.08994V14.4999C11 14.7799 10.78 14.9999 10.5 14.9999Z" fill="#616161"/>
                        </svg>
                        Заведение
                      </div>
                      <div class="past-review-store-name">
                        <a-tooltip placement="topRight" :title="pastReview.store.name">{{ pastReview.store.name }}</a-tooltip>
                      </div>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
          </div>
          <a-comment>
            <template #actions>
            <span key="comment-basic-dislike">
              {{ formattedPhone(review.user?.phone) }}
            </span>
            </template>
            <template #content><p class="past-review-content-detail">{{ pastReview.comment }}</p></template>
          </a-comment>
          <hr>
        </div>
      </div>
      <div v-if="pane.key === 'logs'">
        <div v-if="review && review['logs']">
          <table class="table">
            <tr>
              <th>Действие</th>
              <th>Пользователь</th>
              <th>Дата</th>
            </tr>
            <tr v-if="review && review.log">
              <td>
                <span v-if="review.log['updated_user']">Взял за работу:</span>
              </td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-if="review.log['updated_user']" v-html="review.log['updated_user'].username"></span>
                <!--eslint-enable-->
              </td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-if="review.log['updated_on']" v-html="formattedDate(review.log['updated_on'])"></span>
                <!--eslint-enable-->
              </td>
            </tr>
            <tr v-if="(review && review.log) && review.status !== 'closed'">
              <td>
                <span v-if="review.log['completed_user']">Завершил:</span>
              </td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-if="review.log['completed_user']" v-html="review.log['completed_user'].username"></span>
                <!--eslint-enable-->
              </td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-if="review.log['completed_on']" v-html="formattedDate(review.log['completed_on'])"></span>
                <!--eslint-enable-->
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="text-center my-3">
          <a-empty :image="simpleImage" />
        </div>
      </div>
      <div v-if="pane.key === 'tax'">
        {{ review.tax['fm_num'] }}<br/>
        <span>Номер чека: {{ review.tax['id'] }}<br/></span>
        <span v-if="review.tax['receipt']">{{ review.tax['receipt']?.['Time'] }}<br/></span>
        <span v-else>{{ review.tax['added_on'] }}</span>
        <table class="table w-100 mt-4">
          <thead>
            <tr v-if="review.tax['receipt']">
              <th>Номи</th>
              <th class="text-right">Narxi</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in review.tax['receipt']?.Items" :key="key">
            <td>
              <strong>{{ item.Name }}</strong><br/>
              QQS qiymati<br/>
              Chegirma/Boshqa<br/>
              Shtrix kodi<br/>
              MXIK kodi<br/>
              Komitent STIRi<br/>
            </td>
            <td class="text-right">
              <strong>{{ formattedBalance(item['Price'], true) }}</strong><br/>
              {{ formattedBalance(item['VAT'], true) }}<br/>
              {{ formattedBalance(item['Discount'], true) }}/{{ formattedBalance(item['Other'], true) }}<br/>
              {{ item['Barcode'] }}<br/>
              {{ item['SPIC'] }}<br/>
              {{ item['CommissionInfo']['TIN'] }}<br/>
            </td>
          </tr>
          </tbody>
          <tfoot>
            <tr v-if="review.tax['receipt']">
              <td>Naqd pul</td>
              <td class="text-right">{{ formattedBalance(review.tax['receipt']?.['ReceivedCash'], true) }}</td>
            </tr>
            <tr v-if="review.tax['receipt']">
              <td>Bank kartalari</td>
              <td class="text-right">{{ formattedBalance(review.tax['receipt']?.['ReceivedCard'], true) }}</td>
            </tr>
            <tr v-if="review.tax['receipt']">
              <th>JAMI TO'LOV</th>
              <th class="text-right">{{ formattedBalance(review.tax['receipt']?.['ReceivedCard'] + review.tax['receipt']?.['ReceivedCash'], true) }}</th>
            </tr>
            <tr v-if="review.tax['receipt']">
              <td>Umumiy QQS qiymati</td>
              <td class="text-right">{{ formattedBalance(review.tax['receipt']?.['TotalVAT'], true) }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import { StarTwoTone, UserOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons-vue';
import apiClient from "@/services/axios";
import { message } from 'ant-design-vue';
import { onMounted, ref } from "vue";
import moment from "moment/moment";
import { mask } from "maska";
import OrderDetails from "@/models/OrderDetails";
import { Empty } from "ant-design-vue";

const props = defineProps({
  id: {
    type: Number,
    default: ref(0),
  },
})

const emit = defineEmits(['close'])

const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

const panes = ref([
  {
    title: 'Отзыв',
    content: 'review',
    key: 'review',
    closable: false,
  },
  {
    title: 'История',
    content: 'logs',
    key: 'logs',
    closable: false,
  },
]);

let orderDetails = ref({}),
  review = ref(null),
  comments = ref([]),
  solutions = ref([]),
  comment = ref(''),
  solution = ref(''),
  activeTab = ref('review'),
  pastReviews = ref([]),
  commentButtonLoading = ref(false),
  solutionButtonLoading = ref(false),
  commentsLoading = ref(false),
  solutionsLoading = ref(false),
  loading = ref(false);

const formattedDate = (value, short = false) => {
    let diff = moment(value).diff(moment(), 'days');
    let uint = number =>  Math.sqrt(Math.pow(number, 2));
    let date;
    if (uint(diff) > 1)
      date = short
        ? moment(value).locale('ru').format('D MMM в HH:mm')
        : moment(value).locale('ru').format('YYYY.MM.DD в HH:mm')
    else
      date = moment(value).locale('ru').calendar()

    return date;
  },
  formattedUsername = (s) => {
    return s ? s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } ) : '';
  },
  formattedBalance = (balance, penny = false) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let actualBalance = penny ? balance / 100 : balance
    let formattedBalance = formatter.format(actualBalance)
    return formattedBalance.replace(",00", "")
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  updateStatus = (status) => {
    loading.value = true
    apiClient.put(`/report/${props.id}?status=${status}`).then(({data}) => {
      review.value = data.data
      message.success('Progress', 5);
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  sendComment = (is_solution = false) => {
    if (is_solution) {
      solutionButtonLoading.value = true
      solutionsLoading.value = true
    }
    else {
      commentButtonLoading.value = true
      commentsLoading.value = true
    }
    apiClient.post(`/report/comment?review=${props.id}`, {
      comment: is_solution ? solution.value : comment.value,
      is_solution: is_solution,
    }).then(({data}) => {
      review.value = data.data
      comments.value = (data.data.log) ? data.data.log['comments'] : []
      solutions.value = (data.data.log) ? data.data.log['solutions'] : []
      // message.success('Progress', 5);
      // message.success('Closed', 5);
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      if (is_solution) {
        solution.value = ''
        solutionButtonLoading.value = false
        solutionsLoading.value = false
      } else {
        comment.value = ''
        commentButtonLoading.value = false
        commentsLoading.value = false
      }
    });
  },
  updateComment = (comment, is_solution = false, method = 'update') => {
    let body;
    if (method === 'delete') {
      if (!confirm('Удалить комментарий?')) {
        return;
      }
      body = {
        deleted_at: new Date(),
      }
    } else {
      body = {
        comment: comment.comment,
      }
    }
    if (is_solution) solutionsLoading.value = true
    else commentsLoading.value = true
    apiClient.put(`/report/update-comment?id=${comment.id}`, body)
      .then(({data}) => {
        review.value = data.data
        comments.value = (data.data.log) ? data.data.log['comments'] : []
        solutions.value = (data.data.log) ? data.data.log['solutions'] : []
      })
      .catch(err => {
        if (is_solution) solutionsLoading.value = false
        else commentsLoading.value = false
      })
      .finally(() => {
      if (is_solution) solutionsLoading.value = false
      else commentsLoading.value = false
    });
  };

onMounted(async () => {
  loading.value = true
  await apiClient.get(`/report/${props.id}`).then(({data}) => {
    review.value = data.data
    comments.value = (data.data.log) ? data.data.log['comments'] : []
    solutions.value = (data.data.log) ? data.data.log['solutions'] : []
    if (data.data.tax) {
      panes.value.push({
        title: 'Фискальный чек',
        content: 'tax',
        key: 'tax',
        closable: false,
      })
    }
    if (data.data['order_details'] && data.data['order_details'].length !== 0) {
      orderDetails.value = new OrderDetails(data.data['order_details'])
      panes.value.push({
        title: 'Детали счета',
        content: 'cheque',
        key: 'cheque',
        closable: false,
      })
    }
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    loading.value = false
  });
  await apiClient.get(`/report/past-reviews?review=${props.id}`).then(({data}) => {
    pastReviews.value = data.data
    let count = pastReviews.value.length
    if (count)
      panes.value.push({
        title: `Прошлые отзывы (${count})`,
        content: 'reviews',
        key: 'reviews',
        closable: false,
      })
  }).catch(() => {
    // console.log(err)
  }).finally(() => {});
})

const tabClick = (value) => {
  if (value === 'cheque' && review.value) {
    if (orderDetails.value.longitude && orderDetails.value.latitude) {
      window.ymaps.ready(function () {
        let myMap = new window.ymaps.Map("map", {
          center: [orderDetails.value['latitude'], orderDetails.value['longitude']],
          zoom: 16,
          controls: [],
        })

        myMap.geoObjects
          .add(new ymaps.Placemark([orderDetails.value['latitude'], orderDetails.value['longitude']], {
          }, {
            preset: 'islands#icon',
            iconColor: '#61c89e',
          }));

        myMap.events.add('click', function (e) {
          window.open(`https://yandex.ru/maps/?whatshere[point]=${orderDetails.value['longitude']},${orderDetails.value['latitude']}&whatshere[zoom]=16`, "_blank")
        });
      });

    }
  }
},
close = () => {
  emit('close')
}
</script>

<style scoped src="./review-details.css"></style>
