<template>
  <div>
    <a-affix :offset-top="0">
      <a-page-header class="header-affix mb-4">
        <a-form>
          <div class="row">
            <div class="col-10 mb-4">
              <a-radio-group @change="changeSelection" :disabled="loading" v-model:value="selection">
                <a-radio-button value="all">Все время</a-radio-button>
                <a-radio-button value="year">Год</a-radio-button>
                <a-radio-button value="month">Месяц</a-radio-button>
                <a-radio-button value="week">Неделя</a-radio-button>
                <a-radio-button value="yesterday">Вчера</a-radio-button>
                <a-radio-button value="today">Сегодня</a-radio-button>
              </a-radio-group>

              <a-range-picker
                style="width: 400px"
                :show-time="{ format: 'HH:mm' }"
                class="ml-4"
                :disabled-date="disabledDate"
                @change="updateDate"
                v-model:value="dateRange"
                v-if="selection !== 'all'"
                :placeholder="['Выберите дату', 'Выберите дату']"
              />
            </div>
            <div class="col-2">
              <a-button @click="clearFilters" class="w-100 text-right" shape="round" type="link">
                <template #icon><CloseCircleOutlined /></template>
                Сбросить фильтр
              </a-button>
            </div>
            <div class="col-md-12 mb-2">
              <div class="row">
                <div class="col-md-2">
                  <a-select
                    v-model:value="query.brand_id.value"
                    :options="brandsFilter"
                    :option-filter-prop="'label'"
                    placeholder="Бренд"
                    style="width: 100%"
                    show-search
                    allow-clear
                  />
                </div>

                <div class="col-md-2">
                  <a-select
                    v-model:value="query.store_id.value"
                    :options="storesFilter"
                    :option-filter-prop="'label'"
                    placeholder="Заведение"
                    style="width: 100%"
                    show-search
                    allow-clear
                  />
                </div>

                <div class="col-md-2">
                  <a-select
                    v-model:value="query.employee_id.value"
                    :options="employeesFilter"
                    :option-filter-prop="'label'"
                    placeholder="Сотрудник"
                    style="width: 100%"
                    show-search
                    allow-clear
                  />
                </div>

                <div class="col-md-2">
                  <a-select
                    v-model:value="query.cashdesk.value"
                    :options="cashdeskFilter"
                    :option-filter-prop="'label'"
                    placeholder="Кешдеск"
                    style="width: 100%"
                    show-search
                    allow-clear
                  />
                </div>

                <div class="col-md-2">
                  <a-select show-search v-model:value="query.comment.value" placeholder="Комментарии" style="width: 100%">
                    <a-select-option value="">Все</a-select-option>
                    <a-select-option value="with">С комментариями</a-select-option>
                    <a-select-option value="without">Без комментариев</a-select-option>
                  </a-select>
                </div>

                <div class="col-md-2">
                  <a-select show-search v-model:value="query.rating.value" placeholder="Оценка" style="width: 100%">
                    <a-select-option value="">Оценка</a-select-option>
                    <a-select-option value="1">1</a-select-option>
                    <a-select-option value="2">2</a-select-option>
                    <a-select-option value="3">3</a-select-option>
                    <a-select-option value="4">4</a-select-option>
                    <a-select-option value="5">5</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2">
                  <a-select allow-сlear show-search v-model:value="query.type.value" class="mb-2 mb-md-0" placeholder="Тип" style="width: 100%">
                    <a-select-option value="">Не указано</a-select-option>
                    <a-select-option value="cashbox">Касса</a-select-option>
                    <a-select-option value="delivery">Доставка</a-select-option>
                    <a-select-option value="static">Статичный</a-select-option>
                  </a-select>
                </div>
                <div class="col-md-2">
                  <a-input v-model:value="searchInput" @change="searchUser" placeholder="Поиск по клиенту" />
                </div>
                <div class="col-md-2">
                  <a-input class="mb-2 mb-md-0" v-model:value="searchByAccount" @change="searchAccount" placeholder="Номер счёта " />
                </div>
                <div class="col-md-2">
                  <a-form-item label="Чаевые" class="mb-0">
                    <a-switch v-model:checked="query.tips.value" checked-children="Есть" un-checked-children="Нет" />
                  </a-form-item>
                </div>
                <div class="col-md-2">
                  <a-form-item label="Счет" class="mb-0">
                    <a-switch v-model:checked="query.bill.value" checked-children="Есть" un-checked-children="Нет" />
                  </a-form-item>
                </div>
                <div class="col-md-2">
                  <a-button @click="exportExcel" type="primary" shape="round" class="float-right" :loading="exportLoading">
                    <template #icon>
                      <DownloadOutlined />
                      Экспорт в excel
                    </template>
                  </a-button>
                  <a-modal v-model:visible="exportModal" title="Экспорт отзывов" @ok="closeExportModal">
                    {{ exportStatusTitle }}
                    <a-progress
                      :stroke-color="{
                        from: '#108ee9',
                        to: '#87d068',
                      }"
                      :percent="Math.round(exportPercent)"
                      status="active"
                    />
                  </a-modal>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </a-page-header>
    </a-affix>
    <vb-number-cards
      v-if="route.params.status === 'all'"
      :key="numberKeysCount"
      :total="total"
      :loading="loading"
      :positive="positiveReviews"
      :negative="negativeReviews"
      :average="averageRating"
      :without-rating="reviewsWithoutRating"
    />

    <a-list :data-source="reviewData" :loading="loading" row-key="id">
      <template #renderItem="{ item }">
        <a-list-item class="review-list-item pointer" @click="openModal(item.id)">
          <a-list-item-meta>
            <template #title>
              <div class="row review-list-item-row">
                <div class="col-md-5">
                  <a-comment>
                    <template #actions>
                    <span key="comment-basic-dislike">
                      {{ formattedPhone(item.user?.phone) }}
                    </span>
                    </template>
                    <template #author>
                      <p>
                        <span class="review-username">{{ formattedUsername(item.user?.name) }}</span>
                        <a-tag v-if="item.status === 'new'" color="default">Новый</a-tag>
                        <a-tag v-if="item.status === 'progress'" color="processing">В работе</a-tag>
                        <a-tag v-if="item.status === 'closed'" color="success">Закрыт</a-tag>
                      </p>
                      <div>
                        <span class="review-rating" v-if="item.rating">
                          <span v-for="i in item.rating" :key="i">
                            <StarTwoTone :style="{ fontSize: '18px' }" :two-tone-color="'#00b200'" />
                          </span>
                        </span>
                        <span class="review-added-on">{{ formattedDate(item.added_on) }}</span>
                      </div>
                    </template>
                    <template #content>
                      <p v-if="item.comment" class="review-comment">{{ item.comment }}</p>
                      <span v-else class="text-secondary">Без комментария</span>
                      <p class="text-muted mt-3">ID: {{ item.id }}</p>
                    </template>
                  </a-comment>
                  <div class="ml-3 mt-n4 d-flex align-items-center">
                       <audio v-if="item.audio" class="review-audio mr-3" controls controlsList="nodownload noplaybackrate">
                         <source :src="item.audio" type="audio/ogg">
                         <source :src="item.audio" type="audio/mpeg">
                         Your browser does not support the audio element.
                       </audio>
                    <div v-for="itemSticker in JSON.parse(item.stickers)" :key="itemSticker" class="mr-3">
                      <img width="25" :src="handleSticker(itemSticker)" alt="">
                    </div>
                  </div>
                </div>
                <div class="col-md-2 bordered">
                  <div v-if="item.order_details" class="my-3">
                    <div v-if="item.order_details.orderSum" class="my-2">Сумма счета: {{ formattedBalance(item.order_details.orderSum, true) }}</div>
                    <div v-if="item.order_details.number" class="my-2">Номер счета: {{ item.order_details.number }}</div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-md-6">
                      <a-list item-layout="horizontal">
                        <a-list-item>
                          <a-list-item-meta>
                            <template #description>
                              {{ item.store.name }}
                            </template>
                            <template #title>Заведение</template>
                            <template #avatar>
                              <img :src="item['storeLogo']['value']" alt="" class="store-logo">
                            </template>
                          </a-list-item-meta>
                        </a-list-item>
                        <a-list-item>
                          <a-list-item-meta>
                            <template #title>
                              <div v-if="item.paid">
                                <div>Счет оплачен</div>
                                <div>{{ formattedBalance(item.paid, true) }}</div>
                              </div>
                              <span v-else style="max-width: 100px;display: block;" class="text-secondary">Без оплаты счета</span>
                            </template>
                            <template #avatar>
                              <a-avatar v-if="item.paid" class="contain" src="/resources/images/cheque-active.svg" style="width: 33px" />
                              <a-avatar v-else class="contain" src="/resources/images/cheque.svg" style="width: 33px"></a-avatar>
                            </template>
                          </a-list-item-meta>
                        </a-list-item>
                      </a-list>
                      <a-typography-text
                        type="secondary"
                        v-if="item.order_details?.cashdesk?.name"
                      >Филиал: {{ item.order_details?.cashdesk?.name }}</a-typography-text>
                    </div>
                    <div class="col-md-6">
                      <a-list item-layout="horizontal">
                        <a-list-item>
                          <a-list-item-meta :description="item.employee?.name">
                            <template #title>Сотрудник</template>
                            <template #avatar>
                              <a-avatar v-if="item.employee?.photo_url?.length" class="cover rounded" :src="item.employee?.photo_url" />
                              <a-avatar v-else class="cover rounded" src="/resources/images/avatars/superman.png"></a-avatar>
                            </template>
                          </a-list-item-meta>
                        </a-list-item>

                        <a-list-item>
                          <a-list-item-meta>
                            <template #title>
                              <div v-if="item.tips">
                                <div>Оставил чаевые</div>
                                <div v-if="item?.['wallet_payment']?.['commission']">{{ formattedBalance(item.tips - (item['wallet_payment']['commission'] / 100)) }}</div>
                                <div v-else>{{ formattedBalance(item.tips) }}</div>
                              </div>
                              <span v-else style="max-width: 100px;display: block;" class="text-secondary">Без чаевых</span>
                            </template>
                            <template #avatar>
                              <a-avatar v-if="item.tips" class="contain" src="/resources/images/cup-active.svg" style="width: 33px" />
                              <a-avatar v-else class="contain" src="/resources/images/cup.svg" style="width: 33px"></a-avatar>
                            </template>
                          </a-list-item-meta>
                        </a-list-item>
                      </a-list>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal :width="650" :footer="null" class="review-modal" v-model:visible="detailsModal" title="Детали">
      <vb-review-details @close="closeModal" :id="detailsId" :key="detailsModalKey" />
    </a-modal>
  </div>
</template>

<script setup>
import {StarTwoTone, CloseCircleOutlined, DownloadOutlined} from '@ant-design/icons-vue';
import { ref, watch, reactive, toRefs, onMounted } from "vue";
import VbReviewDetails from "@/views/report/review-details";
import VbNumberCards from "@/views/report/number-cards";
import { useRouter, useRoute } from "vue-router";
import apiClient from "@/services/axios";
import { mask } from "maska";
import moment from "moment";

let
  cashdeskFilter = ref([]),
  storesFilter = ref([]),
  brandsFilter = ref([]),
  employeesFilter = ref([]),
  numberKeysCount = ref(0),
  detailsModal = ref(false),
  detailsId = ref(0),
  detailsModalKey = ref(0),
  timer = null,
  loading = ref(true),
  exportLoading = ref(false),
  exportModal = ref(false),
  exportPercent = ref(0),
  exportStatusTitle = ref('В очереди'),
  exportJobId = ref(0),
  searchInput = ref(''),
  searchByAccount = ref(''),
  total = ref(0),
  positiveReviews = ref(0),
  negativeReviews = ref(0),
  reviewsWithoutRating = ref(0),
  averageRating = ref(0),
  selection = ref('today'),
  dateRange = ref([moment().startOf('day'), moment()]),
  stickers = ref([]),
  reviewData = ref([]);

const
  router = useRouter(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    brand_id: '',
    store_id: '',
    employee_id: '',
    cashdesk: '',
    search: '',
    comment: '',
    rating: '',
    tips: '',
    type: '',
    bill: '',
    bill_id: '',
    from_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    to_date: moment().format('YYYY-MM-DD HH:mm:ss'),
  },
  queryParams = reactive({ ...initialParams }),
  handleSticker = (stickerId) => {
    const elem = stickers.value.find(item => item?.id.toString() === stickerId.toString());
    return elem['icon'];
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  formattedDate = (value) => {
    let diff = moment(value).diff(moment(), 'days');
    let uint = number =>  Math.sqrt(Math.pow(number, 2));
    let date;
    if (uint(diff) > 1)
      date = moment(value).locale('ru').format('YYYY.MM.DD в HH:mm')
    else
      date = moment(value).locale('ru').calendar()

    return date;
  },
  formattedUsername = (s) => {
    return s ? s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } ) : '';
  },
  formattedBalance = (balance, penny = false) => {
    const formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    const actualBalance = penny ? balance / 100 : balance
    const formattedBalance = formatter.format(actualBalance)
    return formattedBalance.replace(",00", "")
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  changeSelection = (event) => {
    let option = event.target.value,
      from,
      to = moment();

    if (option === 'month')
      from = moment().subtract(1, 'months').add(1, 'day')

    if (option === 'week')
      from = moment().subtract(1, 'weeks').add(1, 'day')

    if (option === 'year')
      from = moment().subtract(1, 'years').add(1, 'day')

    if (option === 'yesterday') {
      from = moment().subtract(1, 'day')
      to = moment().startOf('day')
    }

    if (option === 'today')
      from = moment()

    if (option === 'all') {
      dateRange.value = []
      updateParams({ from_date: '', to_date: '' })
      return;
    }

    from.set({hour:0,minute:0,second:0,millisecond:0})

    dateRange.value = [from.utc(true), to.utc(true)]

    updateParams({
      from_date: from.utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19),
      to_date: to.utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19),
    })
  },
  disabledDate = current => {
    return current && current > moment().endOf('day');
  },
  updateDate = (date) => {
    if (date.length) {
      const from = date[0].utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19);
      const to = date[1].utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19);
      updateParams({ from_date: from, to_date: to })
      selection.value = '';
    }
  },
  searchUser = (e) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ search: e.target.value })
    }, 800);
  },
  searchAccount = (e) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ bill_id: e.target.value })
    }, 800);
  },
  clearFilters = () => {
    Object.assign(queryParams, initialParams);

    selection.value = 'day'
    searchInput.value = ''
    searchByAccount.value = ''
    dateRange.value = []
  },
  columns = [
    {
      title: 'Отзыв',
      dataIndex: 'review',
      key: 'review',
      slots: { customRender: 'review' },
    },
    {
      title: 'Детали счета',
      dataIndex: 'details',
      key: 'details',
      slots: { customRender: 'details' },
    },
  ],
  exportDownload = (jobId, interval) => {
    apiClient({
      url: '/report/export-check?id=' + jobId,
      method: "GET",
    }).then((response) => {

      let reserved = +response.data.data.reserved
      let waiting = +response.data.data.waiting
      let done = +response.data.data.done

      if (waiting) {
        exportStatusTitle.value = 'Зарезервирован'
        exportPercent.value = 33
      }

      if (reserved) {
        exportStatusTitle.value = 'Выполняется'
        exportPercent.value = 66
      }

      if (done) {
        exportStatusTitle.value = 'Готово'
        exportPercent.value = 100
        clearInterval(interval)
        apiClient({
          url: '/report/export-download?id=' + jobId,
          method: "GET",
          responseType: 'blob',
        }).then((response) => {
          let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
          let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.${extension}`);
          document.body.appendChild(link);
          link.click();
        }).catch(() => {
          // console.log(err)
        });
      }
    }).catch(() => {
      // console.log(err)
    });
  },
  exportExcel = () => {
    exportLoading.value = true
    let params = new URLSearchParams({
      ...queryParams,
      status: route.params.status,
    })
    apiClient({
      url: '/report/export-prepare?' + params.toString(),
      method: "GET",
    }).then((response) => {
      exportLoading.value = false
      exportModal.value = true
      exportPercent.value = 0;
      exportStatusTitle.value = 'В очереди'
      exportJobId.value = response.data.data
      let interval = setInterval(() => {
        exportDownload(response.data.data, interval)
      }, 5000)
    }).catch(err => {
      exportLoading.value = false
    });
  },
  closeExportModal = () => {
    exportModal.value = false;
  };

const getData = (params) => {
  loading.value = true
  apiClient.get('/report/review', { params }).then(({data}) => {
    reviewData.value = data.data.items
    stickers.value = data.data.stickers
    total.value = data.data.total
    cashdeskFilter.value = data.data.cashdesk
    positiveReviews.value = parseInt(data.data['counts'].positive)
    negativeReviews.value = parseInt(data.data['counts'].negative)
    reviewsWithoutRating.value = parseInt(data.data['counts']['without_rating'])
    averageRating.value = data.data['counts'].average
    brandsFilter.value = data.data.brands
    storesFilter.value = data.data.stores
    employeesFilter.value = data.data['employees']
    numberKeysCount.value++
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    loading.value = false
  });
}

const openModal = (id) => {
  detailsModalKey.value++
  detailsModal.value = true
  detailsId.value = id
}

const closeModal = () => {
  detailsModal.value = false
  getData({
    ...queryParams,
    status: route.params.status,
  })
}

watch(() => route.params.status, () => {
  getData({
    ...queryParams,
    status: route.params.status,
  })
});

watch(queryParams, () => {
  router.push({
    name: 'review',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
    status: route.params.status,
  })
})

onMounted(() => {
  getData({
    ...queryParams,
    status: route.params.status,
  })
})

let query = {...toRefs(queryParams)}

</script>

<style scoped src="./review.css"></style>
